import React from 'react';
import {List} from "@material-ui/core";
import {SearchDocument} from "./SearchDocument";
import {renderIcon} from "../entity/utils/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {CatalogSearchResultListItem} from "./CatalogSearchResultListItem";
import {SearchResult, useSearch} from "@backstage/plugin-search-react";

interface SearchResultsProps {
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
}

export const SearchResults = (props: SearchResultsProps) => {
  const {filters} = useSearch();

  return (
    <SearchResult
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
    >
      {({results}) => (
        // explicitly set onClick and onKeyDown on List component, since for some reason <SearchResult> doesn't set them
        <List onClick={props.onClick} onKeyDown={props.onKeyDown}>
          {results.map(({type, document, highlight, rank}) => {
            let result = document as SearchDocument;
            let originalLocation = result.location;
            let icon = renderIcon(result.kind);
            if (type === 'techdocs') {
              if (filters.type === 'repository') {
                return null;
              }

              icon = <AssignmentIcon/>;

              // rewrite the location to point to the techdocs page inside the actual entity page's "Docs" tab
              result.location = `/catalog/${result.namespace}/${result.kind}/${result.name}/docs/${result.path}`;
            }
            return <CatalogSearchResultListItem
              result={result}
              source={type}
              icon={icon}
              highlight={highlight}
              rank={rank}
              originalLocation={originalLocation}
            />;
          })}
        </List>
      )}
    </SearchResult>
  )
}
